import { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Error, Label } from "@progress/kendo-react-labels";
import { Field, Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { TreeView } from "@progress/kendo-react-treeview";
import { emailValidator, phoneValidator, requiredValidator } from "../../validators";
import { FormInput, FormMaskedTextBox, FormSwitch, FormSwitchList, ListItem } from "../form";
import { LoadingIndicator } from "../LoadingIndicator";
import { useApi } from "../../hooks/useApi";
import { useUserContext } from "../../hooks/useUserContext";
import usersApi from "../../api/users";

interface AddEditUserFormProps {
  cancelEdit: () => void;
  onSubmit: () => void;
  user: any;
  companies: any[];
  permissions: ListItem[];
  rolePermissions: Record<string, number[]>;
}

export const AddEditUserDialog = (props: AddEditUserFormProps) => {
  const { user, companies, permissions, rolePermissions } = props;
  const { context } = useUserContext();
  const addUserApi = useApi(usersApi.create);
  const updateUserApi = useApi(usersApi.update);
  const [isLoading, setIsLoading] = useState(false);
  const [permissionsList, setPermissionsList] = useState<ListItem[]>([]);

  useEffect(() => {
    if (!addUserApi.loading && (addUserApi.data || addUserApi.error))
      if (!addUserApi.error) props.onSubmit();
  }, [addUserApi.loading, addUserApi.data, addUserApi.error]);

  useEffect(() => {
    if (!updateUserApi.loading && (updateUserApi.data || updateUserApi.error))
      if (!updateUserApi.error) props.onSubmit();
  }, [updateUserApi.loading, updateUserApi.data, updateUserApi.error]);

  // Company tree code. TODO: Refactor
  const addTreeNode = (cur: any[], company: any) => {
    let childNodes: any[] = [];
    const childCompanies = companies?.filter((c) => company.children.includes(c.value)) || [];
    childCompanies.forEach((c: any) => addTreeNode(childNodes, c));

    cur.push({
      text: company.label,
      id: company.value,
      role: company.role,
      items: childNodes,
      expanded: true,
    });
  };

  let companyTreeData: any[] = [];

  companies
    .filter((c) => c.value === context.activeCompany.id)
    .forEach((c) => addTreeNode(companyTreeData, c));

  user.companyPermissions = [];
  const [selectedItem, setSelectedItem] = useState<any | null>(null);
  const [companyIds, setCompanyIds] = useState<number[]>(user.companyIds);

  const onCompanyClick = (event: any, formRenderProps: FormRenderProps) => {
    const { item } = event;
    if (selectedItem?.id === item?.id) return;

    const userPermissions = formRenderProps.valueGetter("permissions");
    const companyPermissions = formRenderProps.valueGetter("companyPermissions");

    if (selectedItem) {
      selectedItem.selected = false;
      userPermissions[selectedItem.id] = companyPermissions;
      formRenderProps.onChange("permissions", { value: userPermissions });
    }

    item.selected = true;
    setSelectedItem(item);
    formRenderProps.onChange("companyPermissions", {
      value: userPermissions[item.id] || [],
    });

    const perms = rolePermissions[item.role] ?? [];
    setPermissionsList(permissions.filter((p) => perms.includes(p.value as number)));
  };

  const onExpandChange = (event: any) => {
    event.item.expanded = !event.item.expanded;
    companyTreeData = [...companyTreeData];
  };

  const onPermissionsChange = (event: any, formRenderProps: FormRenderProps) => {
    const newIds =
      event.value.length > 0 && !companyIds.includes(selectedItem.id)
        ? [...companyIds, selectedItem.id]
        : event.value.length === 0 && companyIds.includes(selectedItem.id)
          ? companyIds.filter((i) => i !== selectedItem.id)
          : companyIds;

    setCompanyIds(newIds);
    formRenderProps.onChange("selectedCompanies", {
      value: newIds.length > 0 ? JSON.stringify(newIds) : "",
    });
  };

  const TreeItem = (props: any) => {
    return (
      <span style={{ cursor: "pointer" }}>
        {companyIds.includes(props.item.id) && (
          <span className={`k-icon k-i-check`} style={{ color: "lightgreen" }} />
        )}
        <span
          style={{
            color: props.item.id === selectedItem?.id ? "lightgreen" : "inherit",
          }}
        >
          {props.item.text}
        </span>
      </span>
    );
  };

  // End company tree code

  const handleSubmitEdit = async (event: any) => {
    setIsLoading(true);
    const editUser = { ...event };

    if (selectedItem) editUser.permissions[selectedItem.id] = editUser.companyPermissions;

    Object.keys(editUser.permissions).forEach((k) => {
      if (editUser.permissions[k].length === 0) delete editUser.permissions[k];
    });

    editUser.companyIds = [...Object.keys(editUser.permissions)];
    delete editUser.companyPermissions;

    if (editUser.id === 0) await addUserApi.request(editUser);
    else await updateUserApi.request(editUser);

    setIsLoading(false);
  };

  return (
    <Dialog
      title={user.id > 0 ? `Edit ${user.fullName}` : "Add New User"}
      onClose={props.cancelEdit}
    >
      <div style={{ minWidth: 400 }}>
        <Form
          onSubmit={handleSubmitEdit}
          initialValues={user}
          render={(formRenderProps) => (
            <FormElement>
              <Field
                label="Full Name"
                name="fullName"
                component={FormInput}
                validator={requiredValidator}
                disabled={isLoading}
              />
              <Field
                label="Email Address"
                name="email"
                component={FormInput}
                validator={emailValidator}
                disabled={isLoading}
              />
              <Field
                name="phone"
                label="Phone Number"
                mask="(999) 000-0000"
                required={false}
                component={FormMaskedTextBox}
                validator={phoneValidator}
                disabled={isLoading}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "1rem",
                }}
              >
                <div>
                  <Label>Companies</Label>
                  <Field
                    key="selectedCompanies"
                    id="selectedCompanies"
                    name="selectedCompanies"
                    component={FormInput}
                    validator={requiredValidator}
                    showValidationMessage={true}
                    style={{ visibility: "hidden", display: "none" }}
                  />

                  <TreeView
                    data={companyTreeData}
                    expandIcons={true}
                    item={TreeItem}
                    onExpandChange={onExpandChange}
                    onItemClick={(e) => onCompanyClick(e, formRenderProps)}
                  />
                </div>

                <div>
                  <Label>Permissions</Label>

                  {selectedItem ? (
                    <Field
                      name="companyPermissions"
                      id="companyPermissions"
                      component={FormSwitchList}
                      data={permissionsList}
                      disabled={isLoading || !selectedItem}
                      size="small"
                      onChange={(e) => onPermissionsChange(e, formRenderProps)}
                    />
                  ) : (
                    <>
                      <div>Select a company to set</div>
                      <div>the user's permssions</div>
                    </>
                  )}
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ float: "left" }}>
                  <Field
                    label="Active"
                    name="isActive"
                    component={FormSwitch}
                    defaultChecked={user.isActive}
                    disabled={isLoading}
                    size="small"
                  />
                </div>
              </div>

              <div>
                {updateUserApi.error && <Error>{updateUserApi.error}</Error>}
                {addUserApi.error && <Error>{addUserApi.error}</Error>}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "2rem",
                }}
              >
                <LoadingIndicator loading={isLoading} />

                {!isLoading && (
                  <>
                    <Button themeColor="base" disabled={isLoading} onClick={props.cancelEdit}>
                      Cancel
                    </Button>

                    <Button
                      themeColor="success"
                      type="submit"
                      disabled={!formRenderProps.allowSubmit}
                    >
                      Save
                    </Button>
                  </>
                )}
              </div>
            </FormElement>
          )}
        ></Form>
      </div>
    </Dialog>
  );
};
