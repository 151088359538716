import { LogLevel, RedirectRequest } from "@azure/msal-browser";
import settings from "./settings";

const { azure } = settings;

// Get the current subdomain from window.location.hostname
const hostnameParts = window.location.hostname.split(".");
const subdomain = hostnameParts.length > 2 ? hostnameParts[0] : "";

const isLocalhost = window.location.hostname === "localhost";
const isTestSubdomain = subdomain === "test";
const isMyRiskCanopy = subdomain === "my";

const userFlow =
  !isLocalhost && !isTestSubdomain && !isMyRiskCanopy
    ? `B2C_1_${subdomain}`
    : azure.userFlow;

const uri = `https://${azure.authorityDomain}/${azure.domain}/${userFlow}/`;

export const msalConfig = {
  auth: {
    clientId: azure.clientId,
    authority: uri,
    knownAuthorities: [azure.authorityDomain],
    redirectUri: window.location.protocol + "//" + window.location.host,
    navigateToLoginRequestUrl: false,
    //postLogoutRedirectUri: window.location.protocol + "//" + window.location.host
    
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: string, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          // case LogLevel.Info:
          //     console.info(message);
          //     return;
          // case LogLevel.Verbose:
          //     console.debug(message);
          //     return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

const getQueryParamValue = (paramName: string) => {
  const queryString = window.location.search.substring(1); // Remove the leading "?" character
  const queryParams = queryString.split("&");

  for (const param of queryParams) {
    const [name, value] = param.split("=");
    // Decode the URI-encoded value
    if (name === paramName) return decodeURIComponent(value);
  }

  return ""; // Parameter not found
};

export const loginApiRequest: RedirectRequest = {
  scopes: [`https://${azure.domain}/${azure.apiClientId}/${azure.scope}`],
  loginHint: getQueryParamValue("login_hint"),
};
